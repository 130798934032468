import Vue from 'vue';
import Antd from 'ant-design-vue';
import App from './App.vue';
import router from '@/routes/index';
import store from '@/store/index';
import i18n from '@/libs/i18n';
import Cookies from "js-cookie";
import 'ant-design-vue/dist/antd.css';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import VueMoment from 'vue-moment';
import VueCookies from 'vue-cookies';
import * as helpers from '@/utils/helper';

Vue.config.productionTip = false;
Vue.prototype.$helper = helpers;

Vue.use(Antd);
Vue.use(VueToast);
Vue.use(VueCookies);
Vue.use(VueMoment);

const savedToken = Cookies.get("accessToken");
if (savedToken) {
  store.dispatch("signinByToken", savedToken).then(() => {
  });
}

/* eslint-disable no-new */
new Vue({
  render: h => h(App),
  router,
  store,
  i18n
}).$mount('#app');
