import XLSX from 'xlsx';
import Vue from 'vue';
const moment = require("moment");

export const requiredCheck = (itemId, itemRef, itemMsg, focusOption) => {
  if (itemId === '') {
    Vue.$toast.open({
      message: `${itemMsg}`,
      type: 'error',
      duration: 1000,
      dismissible: true,
    });

    // combo일경우 제외
    if (focusOption === undefined) {
      itemRef.focus();
    }
    return false;
  }
  return true;
}

export const makeExpireDay = days => {
  const date = new Date();
  // const days = 365;
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  const expires = '; expires=' + date.toGMTString() + '; path=/';
  // console.log('', expires);
  return expires;
};

/** 입력값이 NULL인지 체크 */
export const C_isNull = asValue => {
  if (asValue === null || asValue === undefined || asValue.toString().replace(/ /g, '') === '') {
    return true;
  }

  return false;
};

/** 숫자검증 */
export const C_isNum = asValue => {
  if (C_isNull(asValue)) {
    return false;
  }

  for (let i = 0; i < asValue.length; i++) {
    if (asValue.charAt(i) < '0' || asValue.charAt(i) > '9') {
      return false;
    }
  }

  return true;
};

export const zero_plus = str => {
  let result;
  if (str.toString().length === 1) {
    result = '0' + str;
  } else {
    result = str;
  }
  return result;
};

export const xlsxDownload = (jsonData, sheetName, outputFileName) => {
  const now = moment().format("YYYYMMDDhhmmss");
  // console.log(now)
  const excelData = XLSX.utils.json_to_sheet(jsonData);
  const workBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workBook, excelData, sheetName);
  XLSX.writeFile(workBook, `${outputFileName}_${now}.xlsx`);
};

/** 1,000 */
export const commaNum = num => {
  if (num == null) {
    return '';
  }
  const str = num.toString();
  return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
};

/** 콤마 지우기
 * toLocaleString 콤마 지우기
 */
export const Uncomma = str => {
  str = String(str);
  return str.replace(/[^\d]+/g, '');
};

/** 사업자 / 법인 번호 자르기*/
export const bizNumber = (str, type) => {
  if (str === '' || null) return;
  let d = {};
  // 법인번호
  if (type === 'CORP_NO') {
    d.CORP_NO1 = str.substr(0, 6);
    d.CORP_NO2 = str.substr(6, 13);
  }
  if (type === 'BIZM_REG_NO') {
    d.CORP_NO1 = str.substr(0, 3);
    d.CORP_NO2 = str.substr(3, 2);
    d.CORP_NO3 = str.substr(5, 11);
  }
  return d;
};

/** 폽번호 자르기*/
export const phoneNumberDivide = (str, type) => {
  if (str === '' || null) return;
  let d = {};
  // 휴대폰번호 010-1234-0984
  if (type === 'CELL-' || type === '-') {
    const cell = str.split('-');
    d.NO1 = cell[0] === 'undefined' ? '' : cell[0];
    d.NO2 = cell[1] === 'undefined' ? '' : cell[1];
    d.NO3 = cell[2] === 'undefined' ? '' : cell[2];
  }
  // if (type === 'BIZM_REG_NO') {
  //     d.CORP_NO1 = str.substr(0, 3)
  //     d.CORP_NO2 = str.substr(3, 2)
  //     d.CORP_NO3 = str.substr(5, 11)
  // }
  return d;
};

export const TextDiffSecLbl = Diff => {
  let result = Diff + 's';
  if (Diff === '' || Diff === 0) {
    result = '';
  }
  return result;
};

// 특수 문자 체크
export const checkSpecial = str => {
  const regExp = /[!?@#$%^&*():;+-=~{}<>\_\[\]\|\\\"\'\,\.\/\`\₩]/g;
  if (regExp.test(str)) {
    return true;
  } else {
    return false;
  }
};

// 한글 체크 
export const checkKor = str => {
  const regExp = /[ㄱ-ㅎㅏ-ㅣ가-힣]/g;
  if (regExp.test(str)) {
    return true;
  } else {
    return false;
  }
};

// 숫자 체크 
export const checkNum = str => {
  const regExp = /[0-9]/g;
  if (regExp.test(str)) {
    return true;
  } else {
    return false;
  }
};

// 영문(영어) 체크 
export const checkEng = str => {
  const regExp = /[a-zA-Z]/g;
  // 영어
  if (regExp.test(str)) {
    return true;
  } else {
    return false;
  }
};

// 영문+숫자만 입력 체크 
export const checkEngNum = str => {
  const regExp = /[a-zA-Z0-9]/g;
  if (regExp.test(str)) {
    return true;
  } else {
    return false;
  }
};

// 공백(스페이스 바) 체크 
export const checkSpace = str => {
  if (str.search(/\s/) !== -1) {
    return true;
    // 스페이스가 있는 경우
  } else {
    return false;
    // 스페이스 없는 경우
  }
};

// "https://icaregc.s3.ap-northeast-2.amazonaws.com/CMS/work/work-image_20211008164324.jpg" -> 파일명 추출
export const findFileName = fullURL => {
  if (fullURL === null || fullURL === '') return false;
  const strArr = fullURL.split('/');
  // console.log('', strArr[strArr.length - 1]);
  return strArr[strArr.length - 1];
};

// 이미지파일인지 확인~
export const checkImageFile = filename => {
  if (filename === '' || filename === null) return;

  // Image 파일인 경우 미리보기 UI 제공
  const _lastDot = filename.lastIndexOf('.');
  const _fileExt = filename.substring(_lastDot, filename.length).toLowerCase();
  // console.log(_fileExt);
  let imgPreview = false;
  if (_fileExt === '.png' || _fileExt === '.jpg' || _fileExt === '.jpeg' || _fileExt === '.gif') {
    imgPreview = true;
  }
  return imgPreview;
};

// 파일존재여부 확인
export const isFileLabel = fileName => {
  if (fileName === '') {
    return 'N';
  } else {
    return 'Y'
  }
};

// 공통 메세지 처리
export const commonMessage = type => {
  let result = '';
  switch (type) {
    case 'E':
      result = '에러가 발생하였습니다';
      break;
    case 'S':
      result = '저장되었습니다';
      break;
    case 'U':
      result = '수정되었습니다';
      break;
    case 'D':
      result = '삭제되었습니다';
      break;
  }
  return result;
};
