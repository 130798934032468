import {
  SET_ACCESS_TOKEN,
  SET_MY_INFO,
  DESTROY_ACCESS_TOKEN,
  DESTROY_MY_INFO,
  SET_KAKAOINFO,
  SET_HOSPITAL,
  SET_CERTIFY_CARD,
  SET_CERTIFY_USER
} from "./mutations-types";
import api from "@/api";
import Cookies from "js-cookie";

export default {
  [SET_ACCESS_TOKEN](state, accessToken) {
    if (accessToken) {
      state.accessToken = accessToken;
      api.defaults.headers.common["authorization"] = `Bearer ${accessToken}`;

      Cookies.set("accessToken", accessToken, { expires: 100000 });
    }
  },
  [SET_MY_INFO](state, me) {
    if (me) {
      state.me = me;
    }
  },
  [DESTROY_ACCESS_TOKEN](state) {
    state.accessToken = "";
    delete api.defaults.headers.common["authorization"];
    Cookies.remove("accessToken");
  },
  [DESTROY_MY_INFO](state) {
    state.me = null;
  },
  [SET_KAKAOINFO](state, kakaoInfo) {
    if (kakaoInfo) {
      state.kakaoInfo = kakaoInfo;
    }
  },
  [SET_HOSPITAL](state, hospital) {
    if (hospital) {
      state.hospital = hospital;
    }
  },
  [SET_CERTIFY_CARD](state, value) {
    if (value) {
      state.certifyCard = value;
    }
  },
  [SET_CERTIFY_USER](state, value) {
    if (value) {
      state.certifyUser = value;
    }
  },

};
