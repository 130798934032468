import {
  SET_ACCESS_TOKEN,
  SET_MY_INFO,
  DESTROY_ACCESS_TOKEN,
  DESTROY_MY_INFO,
  SET_MEMBERS,
  SET_KAKAOINFO,
  SET_HOSPITAL,
  SET_CERTIFY_CARD,
  SET_CERTIFY_USER,
} from "./mutations-types";
import api from "@/api";
const moment = require("moment");

export default {
  /**
   * [인증]
   */
  //회원가입
  async signup({ commit }, payload) {
    commit(DESTROY_MY_INFO);
    commit(DESTROY_ACCESS_TOKEN);

    payload.formValue.userId = payload.formValue.email;
    payload.formValue.password = payload.formValue.mobile.substr(-4);

    try {
      const res = await api.post("/users/signup", payload.formValue);
      if (res) {
        return res.data.statusCode; //alert("회원가입 성공");
      }
    } catch (err) {
      if (err.response.status === 409) {
        return alert(err.response.data.message);
      } else {
        return alert("회원가입 에러");
      }
    }
  },

  //로그인
  async signIn({ commit }, payload) {
    commit(DESTROY_MY_INFO);
    commit(DESTROY_ACCESS_TOKEN);

    const { userId, password } = payload;
    try {
      const res = await api.post("/users/signin", { userId, password });

      const { accessToken } = res.data.data;
      commit(SET_ACCESS_TOKEN, accessToken);
    } catch (err) {
      if (err.response.status === 401) {
        return alert('아이디 또는 비밀번호를 확인해 주세요');
      } else {
        return alert("로그인 에러 ");
      }
    }

    try {
      const res = await api.get("/users/me");

      if (res) {
        return commit(SET_MY_INFO, res.data.data);
        // return res.data.statusCode;
      }
    } catch (err) {
      return alert("내정보보기 에러");
    }
  },

  //토큰이 있는 경우 내정보 가져오기
  async signinByToken({ commit, dispatch }, token) {
    commit(SET_ACCESS_TOKEN, token);
    try {
      if (token) {
        const res = await api.get("/users/me");

        if (res) {
          return commit(SET_MY_INFO, res.data.data);
        }
      } else {
        this.$router.push({ name: "SignUpPage" });
      }
    } catch (err) {
      // if (err.response.status === 401) {
      //   dispatch("signOut");
      // }
      dispatch("signOut");
      this.$router.push({ name: "SignUpPage" });
    }
  },

  //로그아웃
  signOut({ commit }) {
    commit(DESTROY_MY_INFO);
    commit(DESTROY_ACCESS_TOKEN);
  },

  // 카카오 정보 저장
  saveKakaoInfo({ commit }, payload) {
    commit(SET_KAKAOINFO, payload);
  },

  // 인증카드정보 저장
  saveCertifyCard({ commit }, payload) {
    commit(SET_CERTIFY_CARD, payload);
  },

  // 인증카카오사용자정보 저장
  saveCertifyUser({ commit }, payload) {
    commit(SET_CERTIFY_USER, payload);
  },

  // hcp확인
  async getHcpInfo({ commit }, payload) {
    console.log('', payload);
    const { kakaoChannel, kakaoId, permissionId, queryPath } = payload;
    try {
      const res = await api.post("/users/isHcp", { kakaoChannel, kakaoId, permissionId, queryPath });
      if (res) {
        return res.data.data;
      }
    } catch (err) {
      if (err.response.status === 401) {
        return alert('권한이 없습니다.');
      } else {
        return alert("hcp승인확인 에러 ");
      }
    }
  },

  // hcp확인(휴대폰4자리로)
  async getHcpInfoByMobile4({ commit }, payload) {
    // console.log('', payload);
    const { kakaoChannel, mobile } = payload;
    try {
      const res = await api.post("/users/isHcpByCell", { kakaoChannel, mobile });
      if (res) {
        return res.data.data;
      }
    } catch (err) {
      if (err.response.status === 401) {
        return alert('권한이 없습니다.');
      } else {
        return alert("hcp승인확인 에러 ");
      }
    }
  },

  // 병원검색
  async getHospital({ commit }, payload) {
    // console.log('', payload);
    // return;
    const { selectedCity, selectedGugun, hospitalName } = payload;
    try {
      const res = await api.post("api/hospitalSearch", { selectedCity, selectedGugun, hospitalName });
      if (res) {
        commit(SET_HOSPITAL, JSON.parse(res.data));
        return JSON.parse(res.data);
      }
    } catch (err) {
      if (err.response.status === 401) {
        return alert('권한이 없습니다.');
      } else {
        return alert("병원검색 에러 ");
      }
    }
  },

  // 콘텐츠로그 저장
  async saveViewContentLog({ commit }, payload) {
    // console.log('', payload);
    // return;
    const { content, page, link, kakaoChannel, kakaoUserId, mobile, permissionId } = payload;
    try {
      const res = await api.put("ViewContents/saveLog", { content, page, link, kakaoChannel, kakaoUserId, mobile, permissionId });
      if (res) {
        return res.data;
      }
    } catch (err) {
      if (err.response.status === 401) {
        return alert('권한이 없습니다.');
      } else {
        return alert("콘텐츠로그 저장 에러 ");
      }
    }
  },

  // 친구톡 카드뉴스 로그 저장
  async saveFriendsContentLog({ commit }, payload) {
    const { cardNews, content, page, kakaoChannel, mobile } = payload;
    try {
      const res = await api.put("friendsContents/saveLog", { cardNews, content, page, kakaoChannel, mobile });
      if (res) {
        return res.data;
      }
    } catch (err) {
      if (err.response.status === 401) {
        return alert('권한이 없습니다.');
      } else {
        return alert("친구톡 카드뉴스로그 저장 에러 ");
      }
    }
  }
}
